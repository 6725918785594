import React, { useState } from 'react'
import { Place } from '../models/shared/Place';
import { useFirebaseDoc } from '../hooks/firebaseHookst';
import { COLLECTIONS } from '../utils/shared/constants';
import LocationSearch from './LocationSearch';
import { Source } from '../models/shared/Source';
import { SearchPlaceCandidate } from '../models/shared/api';
import { formatDate } from '../utils/format';
import { AdvancedButton } from './AdvancedButton';

interface Props {
    placeId?: string
    onLocationChosen: (candidate: SearchPlaceCandidate, source: Source) => Promise<void>
    handlePlaceUpdate: (placeId: string, placeSecondaryId: string, placeName: string, source: Source) => void
    processing: boolean
    canDelete: boolean
    onDelete: () => void
}

function PlaceInput(props: Props) {
    const [forceSearch, setForceSearch] = useState(false);
    const [place] = useFirebaseDoc<Place>(`${COLLECTIONS.PLACES}/${props.placeId}`);

    const showSearch = !place || forceSearch;

    if (showSearch) {
        return <LocationSearch
            onLocationChosen={async (candidate, source) => {
                await props.onLocationChosen(candidate, source)
                setForceSearch(false);
            }}
            canCancel={!!place}
            onCancel={() => setForceSearch(false)}
        />
    }
    return <div className="d-flex justify-content-between align-items-start flex-wrap">
        <div>
            <p className="mb-1">{place?.name} [{place?.source || Source.GOOGLE}]{place?.manual && <span className="text-warning">[imported]</span>}</p>
            <p className="text-muted font-small">Last Update At: {formatDate(place?.updatedAt)}</p>
        </div>
        <div className="d-flex gap flex-wrap">
            {props.canDelete && <AdvancedButton icon="bi-trash" variant="outline-danger" onClick={props.onDelete}>
                Delete
            </AdvancedButton>}

            <AdvancedButton icon="bi-arrow-clockwise" variant="outline-secondary" processing={props.processing} onClick={() =>
                props.handlePlaceUpdate(place?.placeId || '', place.placeSecondaryId || '', place?.name || '', place.source || Source.GOOGLE)}>
                Refetch
            </AdvancedButton>

            <AdvancedButton icon="bi-geo-alt" variant="outline-primary" onClick={() => setForceSearch(true)}>
                Change place
            </AdvancedButton>
        </div>
    </div>
}

export default PlaceInput
