import { doc, updateDoc } from "firebase/firestore";
import React, { FC, useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { COLLECTIONS, PATHS } from '../utils/shared/constants';
import { useFirestore } from 'reactfire';
import { useAuth, } from 'reactfire';
import { Instance, InstancePlace } from '../models/shared/Instance';
import { useParams } from 'react-router-dom';
import { Slide, toast } from 'react-toastify';
import WidgetPreview from '../components/WidgetPreview';
import { NavLink } from 'react-router-dom';
import { AdvancedButton } from '../components/AdvancedButton';
import { ensurePlaceLoaded } from "../utils/api";
import { useFirebaseDoc } from "../hooks/firebaseHookst";
import { SearchPlaceCandidate } from "../models/shared/api";
import { Source } from "../models/shared/Source";
import PlaceInput from "../components/PlaceInput";
import InstanceOptions from "../components/InstanceOptions";

interface IProps { }

/**
* @author
* @function @InstancePage
**/

let autosaveTimeout: NodeJS.Timeout | null = null;
const AUTOSAVE_INTERVAL = 1000;


export const InstancePage: FC<IProps> = (props) => {
    const firestore = useFirestore()
    const { currentUser } = useAuth();
    const { instanceId } = useParams<{ instanceId: string }>();
    const [editName, setEditName] = React.useState(false);
    const [instance, setInstance] = useFirebaseDoc<Instance>(`${COLLECTIONS.INSTANCES}/${instanceId}`);
    const [previewRenderCounter, setPriviewRenderCounter] = useState(0)
    const [processing, setProcessing] = useState(false);
    const [placeInputs, setPlaceInputs] = useState<(string | undefined)[]>([undefined]);

    useEffect(() => {
        if (instance?.places?.length) {
            setPlaceInputs(instance.places.map(p => p.placeId));
        }
    }, [instance?.places])

    if (!instance) {
        return <Spinner animation="border" />
    }

    const handlePlaceChosen = async (candidate: SearchPlaceCandidate, source: Source, index: number) => {
        const places = instance.places || [];
        const newPlace: InstancePlace = {
            placeId: candidate.place_id,
            placeName: candidate.name,
        }
        if (candidate.place_secondary_id) {
            newPlace.placeSecondaryId = candidate.place_secondary_id;
        }
        places[index] = newPlace;

        const instanceUpdate: Partial<Instance> = { places };
        await updateDoc(doc(firestore, `${COLLECTIONS.INSTANCES}/${instanceId}`), instanceUpdate);
        setInstance({
            ...instance,
            ...instanceUpdate
        });

        const token = await currentUser?.getIdToken();
        await ensurePlaceLoaded(token + '', {
            placeId: candidate.place_id,
            placeSecondaryId: candidate.place_secondary_id,
            placeName: candidate.name,
            source
        })

        setPriviewRenderCounter(prev => prev + 1);
        toast.success("Place configured!");
    }

    const handlePlaceDeleted = async (index: number) => {
        const places = instance.places || [];
        places.splice(index, 1);
        const instanceUpdate: Partial<Instance> = { places };
        await updateDoc(doc(firestore, `${COLLECTIONS.INSTANCES}/${instanceId}`), instanceUpdate);
        setInstance({
            ...instance,
            ...instanceUpdate
        });
        setPriviewRenderCounter(prev => prev + 1);
    }

    const handlePlaceUpdate = async (placeId: string, placeSecondaryId: string, placeName: string, source: Source) => {
        setProcessing(true);

        try {
            const token = await currentUser?.getIdToken();
            await ensurePlaceLoaded(token + '', { placeId, placeSecondaryId: placeSecondaryId, placeName, source });
            setPriviewRenderCounter(prev => prev + 1);

            toast.success('Place updated');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update place');
        }

        setProcessing(false);

    }

    const autoUpdateInstance = async (instanceUpdate: Partial<Instance>) => {
        if (autosaveTimeout) {
            clearTimeout(autosaveTimeout);
        }

        const newInstance = {
            ...instance,
            ...instanceUpdate
        }
        setInstance(newInstance);

        autosaveTimeout = setTimeout(async () => {
            if (newInstance.name !== '') {

                await updateDoc(doc(firestore, `${COLLECTIONS.INSTANCES}/${instanceId}`), newInstance);
                setPriviewRenderCounter(prev => prev + 1);
                toast.info('Saved and published', {
                    icon: "✔️",
                    autoClose: 1000,
                    // className: 'small-toast',
                    position: "bottom-center",
                    transition: Slide,
                    hideProgressBar: true,
                })
            }
        }, AUTOSAVE_INTERVAL);
    }

    const nameInputWidth = (instance.name?.length || 0) + (editName ? 9 : 0);
    const optionsDisabled = !instance.places?.length;

    return (
        <>
            <div className="d-flex justify-content-between">

                <Form onSubmit={(e) => e.preventDefault()}>
                    <h3 className='d-flex align-items-center'>
                        <Form.Control
                            value={instance.name}
                            onChange={(e) => autoUpdateInstance({ name: e.target.value })}
                            style={{ width: nameInputWidth + 'ch' }}
                            plaintext={!editName} type="text"
                            isInvalid={!instance.name} />
                        <Button variant='plain' type="submit" onClick={() => setEditName(!editName)} disabled={editName && !instance.name.length}>
                            <i className={`bi ${editName ? 'bi-check' : 'bi-pencil-square'} ms-2`}></i>
                        </Button>
                    </h3>
                </Form>


            </div>
            <p>Start configuring your widget</p>
            <div className='my-5'>

                <h5>{placeInputs.length > 1 ? 'Places' : 'PLace'}</h5>

                {placeInputs.map((placeId, index) => <PlaceInput key={index}
                    placeId={placeId}
                    onLocationChosen={(candidate, source) => handlePlaceChosen(candidate, source, index)}
                    handlePlaceUpdate={handlePlaceUpdate}
                    processing={processing}
                    canDelete={index > 0}
                    onDelete={() => handlePlaceDeleted(index)}
                />
                )}

                <AdvancedButton size="sm" icon="bi-plus" variant="outline-secondary" onClick={() => {
                    setPlaceInputs([...placeInputs, undefined])
                }}>
                    Add Place
                </AdvancedButton>

            </div>


            <div className="my-5 options" style={{ maxHeight: optionsDisabled ? 0 : 1000 }}>
                <InstanceOptions instance={instance} autoUpdateInstance={autoUpdateInstance} />

            </div>

            <div className='my-5'>
                <h5>Preview</h5>
                <WidgetPreview key={previewRenderCounter} instanceId={instanceId + ''} />
            </div>
            <div className='d-flex justify-content-md-end justify-content-between mb-5'>
                <NavLink to='/'>
                    <AdvancedButton icon="bi-arrow-left" className='me-2' variant='outline-primary' type="button">Back</AdvancedButton>
                </NavLink>
                <NavLink to={'/' + PATHS.ADD_TO_SITE + '/' + instanceId} >
                    <AdvancedButton icon="bi-stars" className="h-100" disabled={optionsDisabled}>Add to website</AdvancedButton>
                </NavLink>
            </div>

        </>
    )
}